import React,{useEffect} from 'react'
const PrivacyPolicy = () => {
    useEffect(() => {
        document.title = "Privacy Policy - ChennaiGames";
      }, []);
    return (
        <section className="about-section">
		<div className="auto-container">	
        <div className="sec-title centered">
                    {/* <div className="title">our Good</div> */}
                    <h2>Privacy Policy</h2>
                    <div className='title'>EFFECTIVE OCT 08, 2024</div>
                </div>
			<div className="lower-content">
				<div className="row clearfix">
					
					{/* <div className="column col-lg-12 col-md-12 col-sm-12">
                    <p className="about-content">ChennaiGames's mission is to create quality & deep games to engage people with fun and wowness in a entertaining way!</p>

                    <p className="about-content">In pursuit of our mission, we collect & use certain information from our players to improve the quality of our games.We are transparent about our use of players'information and respect their privacy & it is highly important to us.</p>

                    <p className="about-content">This Privacy Policy applies when you use our Games & services.Please read it before using ChennaiGames'Services because it will tell you how we collect,store,use,and disclose your personal information when providing these services to you.</p>

                    <p className="about-content">If you have analysis questions,comments,or concerns regarding our Privacy Policy or practices,please Contact Us.</p>
					</div> */}

                    <div className="column col-lg-12 col-md-12 col-sm-12 privacy-title">
						<h3 style={{fontSize:'25px'}}>Introduction</h3>
					</div>
					
					<div className="column col-lg-12 col-md-12 col-sm-12">
                    <p className="about-content">At ChennaiGames, our mission is to create engaging, high-quality games that provide fun and excitement for all players. In order to achieve this, we collect and use certain player information to improve the gaming experience. We value transparency and respect for your privacy, which is a top priority for us. This Privacy Policy explains how we collect, store, use, and disclose your personal information when you interact with our games and services.</p>

                    <p className="about-content">By using any of ChennaiGames services, you agree to the practices outlined in this Privacy Policy. If you have any questions or concerns about how we handle your data, feel free to contact us at <a href="mailto:privacy@chennaigames.com?">privacy@chennaigames.com</a>.</p>

                    {/* <p className="about-content">If you do not want ChennaiGames to collect, store, use, or share your information in the ways described in this Privacy Policy, kindly you don't play ChennaiGames' games or use ChennaiGames' other Services.</p> */}
					</div>

                    <div className="column col-lg-12 col-md-12 col-sm-12">
                    <h3 style={{fontSize:'25px'}}>1. Information We Collect</h3>
					</div>

                    <div className="column col-lg-12 col-md-12 col-sm-12">
                    <p className="about-content">When you use our games and services, we may collect the following types of information:</p>

                    <p className="about-content">
                        <ul style={{listStyle:'unset',paddingLeft:'50px'}}>
                            <li style={{fontSize:'20px'}}><b>Game Data:</b> Information about your gameplay, such as the games you play, session duration, progress, and achievements.</li>
                            <li style={{fontSize:'20px'}}><b>Device Information:</b> Details about your device, including device ID, operating system, model, and technical specifications.</li>
                            <li style={{fontSize:'20px'}}><b>Location Data:</b> With your permission, we may collect location data to provide location-based ads and offers.</li>
                            <li style={{fontSize:'20px'}}><b>Referral Information:</b> Information about any referral codes used.</li>
                            <li style={{fontSize:'20px'}}><b>Analytics Data:</b> Information about your gameplay performance, including the number of game attempts, failures, and successes.</li>
                        </ul>
                    </p>

                    <p className="about-content">This information is used to improve gameplay, provide you with the best possible experience, and analyze our games' performance.</p>
					</div>

                    <div className="column col-lg-12 col-md-12 col-sm-12">
                    <h3 style={{fontSize:'25px'}}>2. Why We Collect Information</h3>
					</div>

                    <div className="column col-lg-12 col-md-12 col-sm-12">
                    <p className="about-content">We collect this information to:</p>
                    <p className="about-content">
                        <ul style={{listStyle:'unset',paddingLeft:'50px'}}>
                            <li style={{fontSize:'20px'}}>Provide, maintain, and improve our games and services.</li>
                            <li style={{fontSize:'20px'}}>Track your progress and game status.</li>
                            <li style={{fontSize:'20px'}}>Identify and fix bugs or issues that impact gameplay.</li>
                            <li style={{fontSize:'20px'}}>Offer relevant, location-based advertisements and promotions (optional).</li>
                        </ul>
                    </p>
					</div>

                    <div className="column col-lg-12 col-md-12 col-sm-12">
                    <h3 style={{fontSize:'25px'}}>3. Third-Party Services and Advertisers</h3>
					</div>

                    <div className="column col-lg-12 col-md-12 col-sm-12">
                    <p className="about-content">Our games may include links to third-party websites, services, or advertisements. Please be aware that these third-party services have their own privacy policies. We do not control or take responsibility for how these third parties handle your data. We encourage you to review their privacy policies before engaging with their services.</p>
					</div>

                    <div className="column col-lg-12 col-md-12 col-sm-12">
                    <h3 style={{fontSize:'25px'}}>4. Data Security</h3>
					</div>

                    <div className="column col-lg-12 col-md-12 col-sm-12">
                    <p className="about-content">We take data protection seriously and have implemented various security measures—both technical and physical—to safeguard your information. We continually work to ensure that your data is protected against unauthorised access, misuse, and alteration.</p>
					</div>

                    <div className="column col-lg-12 col-md-12 col-sm-12">
                    <h3 style={{fontSize:'25px'}}>5. Data Deletion Requests</h3>
					</div>

                    <div className="column col-lg-12 col-md-12 col-sm-12">
                    <p className="about-content">If you wish to stop using our services and want your data removed from our systems, you can request data deletion by contacting us at <a href="mailto:privacy@chennaigames.com?">privacy@chennaigames.com</a>.</p>
					</div>

                    <div className="column col-lg-12 col-md-12 col-sm-12">
                    <h3 style={{fontSize:'25px'}}>6. Changes to this Privacy Policy</h3>
					</div>

                    <div className="column col-lg-12 col-md-12 col-sm-12">
                    <p className="about-content">ChennaiGames may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. When changes occur, we will post the updated policy on this page. We encourage you to review this page regularly to stay informed.</p>
					</div>

                    <div className="column col-lg-12 col-md-12 col-sm-12">
                    <h3 style={{fontSize:'25px'}}>7. Contact Us</h3>
					</div>

                    <div className="column col-lg-12 col-md-12 col-sm-12">
                    <p className="about-content">For any questions, concerns, or requests related to this Privacy Policy or your personal data, please contact us at: <a href="mailto:privacy@chennaigames.com?">privacy@chennaigames.com</a></p>
					</div>

				</div>
			</div>
		</div>
	</section>
    )
}

export default PrivacyPolicy