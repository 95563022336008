import React,{useEffect} from 'react'
const TermsOfService = () => {
    useEffect(() => {
        document.title = "Privacy Policy - ChennaiGames";
      }, []);
    return (
        <section className="about-section">
		<div className="auto-container">	
        <div className="sec-title centered">
                    {/* <div className="title">our Good</div> */}
                    <h2>Terms of Service</h2>
                    {/* <div className='title'>EFFECTIVE MAY 24, 2024</div> */}
                </div>
			<div className="lower-content">
				<div className="row clearfix">
					
					{/* <div className="column col-lg-12 col-md-12 col-sm-12">
                    <p className="about-content">ChennaiGames's mission is to create quality & deep games to engage people with fun and wowness in a entertaining way!</p>

                    <p className="about-content">In pursuit of our mission, we collect & use certain information from our players to improve the quality of our games.We are transparent about our use of players'information and respect their privacy & it is highly important to us.</p>

                    <p className="about-content">This Privacy Policy applies when you use our Games & services.Please read it before using ChennaiGames'Services because it will tell you how we collect,store,use,and disclose your personal information when providing these services to you.</p>

                    <p className="about-content">If you have analysis questions,comments,or concerns regarding our Privacy Policy or practices,please Contact Us.</p>
					</div> */}

                    <div className="column col-lg-12 col-md-12 col-sm-12 privacy-title">
						<h3 style={{fontSize:'25px'}}>1. Acceptance of Terms</h3>
					</div>
					
					<div className="column col-lg-12 col-md-12 col-sm-12">
                    <p className="about-content">By accessing or using any services, websites, or games provided by ChennaiGames Studio Private Limited (referred to as "ChennaiGames"), you agree to be bound by these Terms of Service. If you do not agree with any part of these terms, you are prohibited from using or accessing any services offered by ChennaiGames. </p>
					</div>

                    <div className="column col-lg-12 col-md-12 col-sm-12">
                    <h3 style={{fontSize:'25px'}}>2. Use License</h3>
					</div>

                    <div className="column col-lg-12 col-md-12 col-sm-12">
                    <p className="about-content">
                        <ul style={{listStyle:'unset',paddingLeft:'50px'}}>
                            <li style={{fontSize:'20px'}}>Permission is granted to temporarily download one copy of the materials (information or software) on ChennaiGames's websites or apps for personal, non-commercial, transitory viewing only. </li>
                            <li style={{fontSize:'20px'}}>This is the grant of a license, not a transfer of title, and under this license, you may not: 
                                <ul style={{listStyle:'unset',paddingLeft:'50px'}}>
                                    <li style={{fontSize:'20px'}}>modify or copy the materials;</li>
                                    <li style={{fontSize:'20px'}}>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
                                    <li style={{fontSize:'20px'}}>attempt to decompile or reverse engineer any software contained on ChennaiGames' websites or apps;</li>
                                    <li style={{fontSize:'20px'}}>remove any copyright or other proprietary notations from the materials;</li>
                                    <li style={{fontSize:'20px'}}>transfer the materials to another person or "mirror" the materials on any other server.</li>
                                </ul>
                            </li>
                            <li style={{fontSize:'20px'}}>This license shall automatically terminate if you violate any of these restrictions and may be terminated by ChennaiGames at any time. Upon termination of your viewing of these materials or the termination of this license, you must destroy any downloaded materials in your possession, whether in electronic or printed format.</li>
                        </ul>
                    </p>

					</div>

                    <div className="column col-lg-12 col-md-12 col-sm-12">
                    <h3 style={{fontSize:'25px'}}>3. Disclaimer</h3>
					</div>

                    <div className="column col-lg-12 col-md-12 col-sm-12">
                    <p className="about-content">
                    <ul style={{listStyle:'unset',paddingLeft:'50px'}}>
                                    <li style={{fontSize:'20px'}}>The materials on ChennaiGames' websites and apps are provided on an 'as is' basis. ChennaiGames makes no warranties, expressed or implied, and disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. </li>
                                    <li style={{fontSize:'20px'}}>Further, ChennaiGames does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or any sites linked to this site. </li>
                                    
                                </ul>
                                </p>
					</div>

                    <div className="column col-lg-12 col-md-12 col-sm-12">
                    <h3 style={{fontSize:'25px'}}>4. Limitations</h3>
					</div>

                    <div className="column col-lg-12 col-md-12 col-sm-12">
                    <p className="about-content">In no event shall ChennaiGames or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on ChennaiGames' websites or apps, even if ChennaiGames or an authorized representative has been notified orally or in writing of the possibility of such damage. </p>
					</div>

                    <div className="column col-lg-12 col-md-12 col-sm-12">
                    <h3 style={{fontSize:'25px'}}>5. Accuracy of Materials</h3>
					</div>

                    <div className="column col-lg-12 col-md-12 col-sm-12">
                    <p className="about-content">The materials appearing on ChennaiGames's websites or apps could include technical, typographical, or photographic errors. ChennaiGames does not warrant that any of the materials on its website are accurate, complete, or current. ChennaiGames may make changes to the materials contained on its websites or apps at any time without notice. However, ChennaiGames does not make any commitment to update the materials.</p>
					</div>

                    <div className="column col-lg-12 col-md-12 col-sm-12">
                    <h3 style={{fontSize:'25px'}}>6. Links</h3>
					</div>

                    <div className="column col-lg-12 col-md-12 col-sm-12">
                    <p className="about-content">ChennaiGames has not reviewed all of the sites linked to its websites or apps and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by ChennaiGames of the site. Use of any such linked website is at the user's own risk. </p>
					</div>

                    <div className="column col-lg-12 col-md-12 col-sm-12">
                    <h3 style={{fontSize:'25px'}}>7. Modifications to Terms of Service</h3>
					</div>

                    <div className="column col-lg-12 col-md-12 col-sm-12">
                    <p className="about-content">ChennaiGames may revise these terms of service for its website or apps at any time without notice. By using this website or app you are agreeing to be bound by the then-current version of these Terms of Service.</p>
					</div>

                    <div className="column col-lg-12 col-md-12 col-sm-12">
                    <h3 style={{fontSize:'25px'}}>8. Governing Law</h3>
					</div>

                    <div className="column col-lg-12 col-md-12 col-sm-12">
                    <p className="about-content">These terms and conditions are governed by and construed in accordance with the laws of India, and you irrevocably submit to the exclusive jurisdiction of the courts in Chennai, Tamil Nadu.</p>
					</div>

                    <div className="column col-lg-12 col-md-12 col-sm-12">
                    <h3 style={{fontSize:'25px'}}>9. Contact Information</h3>
					</div>

                    <div className="column col-lg-12 col-md-12 col-sm-12">
                    <p className="about-content">For any questions or concerns about these terms, please contact us via the email <a href="mailto:hello@chennaigames.com?">hello@chennaigames.com</a></p>
					</div>

				</div>
			</div>
			
		</div>
	</section>
    )
}

export default TermsOfService


